import App from "next/app";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
const { ATR_HOST } = publicRuntimeConfig;
const hosts = require("../hosts");

const getHost = async (host) => {
  const hostName = host === ATR_HOST ? "atr" : null;

  const brand = hostName ? hosts[hostName] : hosts["trackNight"];
  return brand;
};

class CustomApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let host;
    if (ctx.req) {
      host = ctx.req.headers.host;
    } else {
      host = location.host;
    }
    const brand = await getHost(host);
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ...ctx, host });
    }
    pageProps.brand = brand;
    return { pageProps };
  }

  render() {
    const { Component, pageProps } = this.props;
    return <Component {...pageProps} />;
  }
}
export default CustomApp;
