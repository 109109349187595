const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { HOST, ATR_HOST, ATR_ROOT_URL, API_URL } = publicRuntimeConfig;

module.exports = {
  atr: {
    gaId: "G-YSPD7BPJWP",
    name: "atr",
    host: ATR_HOST || "atr.track-night.com",
    rootUrl: ATR_ROOT_URL,
    logo: "atr1.png",
    footer: {
      title: "In association with At The Races",
      links: [
        { title: "Visit At The Races", href: "https://www.attheraces.com" },
      ],
      image: "atr-bw-png",
    },
  },
  trackNight: {
    gaId: "UA-164040019-1",
    name: "trackNight",
    host: HOST || "localhost:3000",
    rootUrl: API_URL,
  },
};
